.App {
    display: grid;
}
.template{
    display: grid;
    grid-template-columns: 200px 1fr;
}
p{
    font-family: 'Cascadia Mono', sans-serif;
}
