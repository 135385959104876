.about-div{
    margin-top: 100px;
    margin-right: 100px;
    padding: 20px;
    background-color: #081525;
    border-radius: 20px;
    margin-bottom: 300px;
}
.about-text {
    text-align: center;
    font-size: 24px;
    margin-top: 0px;
    color: rgb(126, 126, 126);
    text-align: justify;
}
.about-header {
    text-align: center;
    font-size: 60px;
    margin-top: 0px;
    margin-bottom: 30px;
    color: rgb(255, 255, 255);
}
