.header{
    display: block;
    position: fixed;
    background-color: #111d2c;
    padding-bottom: 10px;
    border-radius: 10px;
    width: 100%;
}
.menu-button {
    font-size: 25px;
    color: rgb(3, 173, 173);
    background: none;
    padding: 15px;
    padding-left: 15px;
    border-radius: 20px;
    border: none;
    margin-right: 20px;
    margin-top: 10px;
    margin-left: 20px;
    transition: all 0.3s;
    width: 170px;
}
.menu-button:hover {
    background-color: rgb(3, 173, 173);
    color: rgb(5, 44, 69);
    cursor: pointer;
}
