.main-div{
    width: 600px;
    margin-top: 100px;
    padding: 20px;
    padding-top: 5px;
    background-color: #081525;
    border-radius: 20px;
    margin-bottom: 300px;
}
.grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
p, h3, button{
    font-family: Kanit;
}
  
.my-image{
    margin-top: 140px;
    width: 400px;
    border-radius: 20px;
    transition: all 0.15s;
}
.my-image:hover{
    transform: rotate(5deg);
}
.question {
    text-align: center;
    font-size: 25px;
    color: rgb(16, 124, 70);
}

.name {
    text-align: center;
    font-size: 60px;
    margin-top: 0px;
    margin-bottom: 30px;
    color: rgb(255, 255, 255);
}

.slogan {
    text-align: center;
    font-size: 50px;
    margin-top: 0px;
    margin-bottom: 30px;
    color: rgb(171, 168, 168);
}

.description {
    text-align: center;
    width: 600px;
    font-size: 24px;
    margin-top: 0px;
    color: rgb(126, 126, 126);
    text-align: justify;
}
